/**
//  * @file
 * A JavaScript file for the theme.
 *
 * In order for this JavaScript to be loaded on pages, see the instructions in
 * the README.txt next to this file.
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - https://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
// (function($, Drupal, window, document) {
//   "use strict";

//   // To understand behaviors, see https://drupal.org/node/756722#behaviors
//   Drupal.behaviors.my_custom_behavior = {
//     attach: function(context, settings) {
// Place your code here.
// Auto Hide Sticky Header

jQuery(document).ready(function($) {
  $(function() {
    var scrollY = window.pageYOffset,
      $header = $(".header, .meanmenu-reveal, .mean-nav");

    $(window).scroll(function() {
      var currentScrollY = window.pageYOffset;
      if (currentScrollY > $header.outerHeight()) {
        $header.addClass("is-hidden");
      } else {
        $header.removeClass("is-hidden");
      }

      if (currentScrollY > scrollY) {
        $header.removeClass("is-show");
      } else {
        $header.addClass("is-show");
      }

      scrollY = currentScrollY;
    });
  });

  //Collapse
  $(".org-structure h2, #board-members h2").on("click", function(e) {
    e.preventDefault();
    $(this).toggleClass("active");
  });

  //verification block - placeholder for input
  if ($("body").hasClass("page-home")) {
    $("#edit-field-trade-mark").attr("placeholder", "Enter the tag number");
  }

  //pop to show result of trademark verification in homepage in popup
  $("#edit-submit-trade-mark-verification").click(function(e) {
    e.preventDefault();

    //adds class js-colorbox-on to the body when the colorbox is opened
    $("body").addClass("js-colorbox-on");

    //load colorbox
    var elementURL = $("#edit-field-trade-mark").val();
    $.get("trade-mark-verification?field_trade_mark=" + elementURL, function(
      data
    ) {
      var width = "90%";
      var height = "auto";

      if ($(window).width() > 780) {
        width = "780";
      }
      if ($(window).height() > 760) {
        height = "auto";
      }

      $.colorbox({
        html: $(data).find(".js-trademark-popup"),
        width: width,
        height: height,
        href: "trade-mark-verification?field_trade_mark=" + elementURL,
        className: "js_verification_popup"
      });
    });

    //unbinds the attached event from previous clicks
    $("#edit-submit-trade-mark-verification").unbind(event);
  });

  //colorbox responsive
  //Customize colorbox dimensions
  var colorboxResize = function(resize) {
    var width = "90%";
    var height = "auto";

    if ($(window).width() > 780) {
      width = "780";
    }
    if ($(window).height() > 760) {
      height = "auto";
    }

    $.colorbox.settings.height = height;
    $.colorbox.settings.width = width;

    //if window is resized while lightbox open
    if (resize) {
      $.colorbox.resize({
        height: height,
        width: width
      });
    }
  };

  //In case of window being resized for the verification popup only
  $(window).resize(function() {
    if ($("body").hasClass("front")) {
      colorboxResize(true);
    }
  });

  //removed the class js-colorbox-on from the body and resets the trademark input field
  if ($("body").hasClass("js-colorbox-on")) {
    $("#edit-field-trade-mark").val("");
    $("body").removeClass("js-colorbox-on");
  }

  // placeholder for input box from the title
  if ($("div").hasClass("custom-exposed-form")) {
    var title = $.trim(
      $(".custom-exposed-form")
        .find(".views-widget label")
        .text()
    );
    $(".custom-exposed-form")
      .find(".form-text")
      .attr("placeholder", title);
  }

  //hide contact email and phone number div if there is no content
  $("div.js-check-empty").each(function() {
    if ($(this).has("a").length == 0) {
      $(this).hide();
    }
  });

  //Form infield label.
  $(
    "form .webform-component-textfield, form .webform-component-email, form .webform-component-textarea"
  ).infieldLabel();
  //     }
  //   };
  // })(jQuery, Drupal, this, this.document);
});
